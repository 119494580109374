import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { useNumberStore } from "../../stores";
import {
  BuyAdditionalNumberPayload,
  getActiveBusinessId,
  InviteTeamMemberPayload,
  PatchTeamMember,
} from "../";

const getReceiverNumberId = () => {
  const numberStore = useNumberStore();
  return numberStore.activeNumber?.business_number.id;
};
interface ResponseData {
  [key: string]: any;
}

export const fetchTeamMembers = async (
  nextUrl?: string
): Promise<ResponseData> => {
  const currentUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/user/?page_size=100`;
  return await fetchWrapper.get(`${nextUrl ? nextUrl : currentUrl}`);
};

export const fetchAllTeamMembers = async (): Promise<ResponseData> => {
  const currentUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/user/?page_index=1&page_size=100`;
  return await fetchWrapper.get(currentUrl);
};

export const deleteTeamMember = async (
  userId: number
): Promise<ResponseData> => {
  return await fetchWrapper.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/`
  );
};

export const inviteTeamMember = async (
  payload: InviteTeamMemberPayload
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/`,
    payload
  );
};

export const fetchPendingInvites = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/receiver_numbers/get_pending_invitations/`
  );
};

export const updateTeamMemberProfile = async (
  payload: PatchTeamMember,
  userId: number
): Promise<ResponseData> => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/profile/`,
    payload
  );
};

export const updateTeamMemberPermission = async (
  payload: { business_numbers: string[]; [key: string]: boolean | string[] },
  userId: number
): Promise<ResponseData> => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/permission/`,
    payload
  );
};

export const revokeTeamMemberInvite = async (
  payload: number | string
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/receiver_numbers/${payload}/revoke_invitation/`
  );
};

export const fetchTeamMembersStatus = async (
  payload: any
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/numbers/${getActiveBusinessId()}/team-members-status/`,
    payload
  );
};

export const buyAdditionalNumberExtensions = async (
  payload: BuyAdditionalNumberPayload
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/buy_additional_number_extensions/`,
    payload
  );
};

export const buyAdditionalNumberExtensionsForExisting = async (
  payload: BuyAdditionalNumberPayload
): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/v2/buy_additional_number_extensions/charge_card_authorization/`,
    payload
  );
};

export const exportTeamMembersCSV = async () => {
  return await fetchWrapper.download(
    `${BASE_API}/api/receiver_numbers/export-csv/?business_number=${getReceiverNumberId()}`,
    "",
    "team-members.csv"
  );
};

export const fetchPermissions = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/permission/`
  );
};

export const fetchTeamMemberDetails = async (
  userId: number
): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/`
  );
};

export const updateSessionActivity = async (): Promise<ResponseData> => {
  return await fetchWrapper.patch(`${BASE_API}/api/user/session-activity/`);
};
