import { defineStore } from "pinia";
import { useAddonStore } from "./index.store";
import { CampaignAgent, CampaignCallQueue } from "../../helpers/types/add-ons/salesCampaign";
interface SalesCampaignAddOnState {
    showCreatecampaignModal: boolean;
    showAssignTeamModal : boolean;
    showDistributeContectModal : boolean;
    showAddSingleContactToCampaign: boolean;
    showEditCampaign: boolean;
    currentAgentViewingCampaign: CampaignAgent| null;
    currentAgentViewingCallQueueDetails: CampaignCallQueue | null;
    showTeamMemberLimitModal: boolean;
    showExtendTeamLimitModal: boolean;
}

export const useSalesCampaignAddOnStore = defineStore({
    id: "sales-campaign-addon",
    state: (): SalesCampaignAddOnState => ({
        showCreatecampaignModal: false,
        showAssignTeamModal : false,
        showDistributeContectModal: false,
        showAddSingleContactToCampaign: false,
        showEditCampaign: false,
        currentAgentViewingCampaign: null,
        currentAgentViewingCallQueueDetails: null,
        showTeamMemberLimitModal: false,
        showExtendTeamLimitModal: false,
  }),
  getters: {
    getAgentHasAccess: () => {
        const addonStore = useAddonStore();
        const hasAccessToCampaign = addonStore.getAllEnhancedAddons.some(
            (addon: any) =>
                (addon.title.includes("Campaign Addon") &&
                    addon.isPurchased)
        );
        return hasAccessToCampaign;
    }
  }
});