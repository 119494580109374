import { defineStore } from "pinia";
import { Surreal } from "surrealdb.js";
import { fetchSurrealAuthToken } from "../helpers/queries/activitystream";
import logger from "../helpers/logger";

const NS = "default";
const DB = "default";
const SURREAL_HOST = "wss://iris.pressone.co:443/rpc";

interface ActivityState {
  surrealdb: Surreal;
  authToken: string | null;
  dbConnected: Boolean;
  activity: Array<any>;
  queryUuid: any;
  auditReport: Array<any>;
}

export const useActivityStore = defineStore({
  id: "activities",
  state: (): ActivityState => ({
    surrealdb: new Surreal(),
    authToken: "",
    dbConnected: false,
    activity: [],
    queryUuid: "",
    auditReport: [],
  }),

  getters: {
    isTokenValid() {
      let decodedJWT = decodeJWT(this.authToken);
      // Check validity
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since the Unix epoch
      const isValid = decodedJWT?.payload.exp
        ? currentTime < decodedJWT.payload.exp
        : false;

      return isValid;
    },
  },

  actions: {
    async fetchToken() {
      const token = await fetchSurrealAuthToken().then((res) => {
        return res.data;
      });
      this.authToken = token?.token;
      return this.authToken || "";
    },

    async instantiateDB() {
      if (!this.surrealdb) {
        return;
      }
      try {
        await this.surrealdb.connect(SURREAL_HOST, {
          auth: await this.fetchToken(),
          namespace: NS,
          database: DB,
        });
        const information = await this.surrealdb.info();
        console.log("INFO", information);
        this.dbConnected = true;
      } catch (error: any) {
        logger.error("Error", error);
        this.dbConnected = false;
      }
    },

    async connectDB() {
      if (!this.dbConnected) {
        await this.instantiateDB();
      }
    },
    async queryDB() {
      const queryUuid = await this.surrealdb.live(
        "activity",

        ({ action, result }) => {
          if (action === "CLOSE") return;
          this.activity.push(result);
        }
      );
      this.queryUuid = queryUuid;
    },

    async listenDB() {
      const result = await this.surrealdb.query(
        "SELECT * FROM activity ORDER BY timestamp DESC"
      );
      this.auditReport = result;
    },
  },
});

function decodeJWT(token: string | null) {
  if (!token) {
    return;
  }

  const [headerEncoded, payloadEncoded] = token.split(".");
  const header = JSON.parse(window.atob(headerEncoded));
  const payload = JSON.parse(window.atob(payloadEncoded));
  return { header, payload };
}
