import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { getActiveBusinessId, getActiveBusinessNumberId } from "../queries";
// import { Plans, Subscription, Subscriptions } from "../types/billings";

const BASE_ENDPOINT = `${BASE_API}/api`;

export const getNumbersReport = async ({
  id,
  from,
  to,
  export_csv,
}: {
  id: any;
  from: string;
  to: string;
  export_csv?: boolean;
}) => {
  try {
    if (export_csv) {
      await fetchWrapper.download(
        `${BASE_ENDPOINT}/telephony/calls/report/?custom_start_date=${from}&custom_end_date=${to}&period=custom&number=${id}&export_csv=true`,
        "",
        "NumbersReport.csv"
      );
      return { data: [] };
    }

    const reports = await fetchWrapper.get(
      `${BASE_ENDPOINT}/business/report/team/?start_date=${from}&business_id=${getActiveBusinessId()}&end_date=${to}&period=custom&number_id=${id}`
    );

    return reports;
  } catch (error) {
    console.log(error);
  }
};

export const getLeaderboardReports = async ({
  id,
  period,
  order_by,
  custom_end_date,
  custom_start_date,
  export_csv,
}: {
  id: string;
  period?: string;
  order_by?: string;
  custom_end_date?: string;
  custom_start_date?: string;
  export_csv?: boolean;
}) => {
  try {
    let queries = ``;

    if (custom_start_date) queries += `&custom_start_date=${custom_start_date}`;
    if (custom_end_date) queries += `&custom_end_date=${custom_end_date}`;

    if (export_csv)
      return await fetchWrapper.download(
        `${BASE_ENDPOINT}/numbers/${id}/team-members-report/?period=${period}&order_by=${order_by}${queries}&export_csv=true`,
        "",
        "leaderboard.csv"
      );

    const reports = await fetchWrapper.get(
      `${BASE_ENDPOINT}/numbers/${id}/team-members-report/?period=${period}&order_by=${order_by}${queries}`
    );

    return reports;
  } catch (error) {
    console.log(error);
  }
};

export const getReceiverNumbers = async (business_number_id?: string) => {
  const id = business_number_id || getActiveBusinessNumberId();
  const agents = await fetchWrapper.get(
    `${BASE_ENDPOINT}/receiver_numbers/list-all-numbers/?business_number=${id}`
  );
  return agents;
};

export const getReceiverIds = async (business_number_id?: string) => {
  const agents = await fetchWrapper.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/number/${business_number_id}/users/`
  );
  return agents;
};

export const getTeamReports = async ({
  id,
  from,
  to,
  export_csv,
}: {
  id: string;
  from?: string;
  to?: string;
  export_csv?: boolean;
}) => {
  let queries = ``;

  if (!id) return;

  if (from) queries += `from_date=${from}`;
  if (to) queries += `&to_date=${to}`;

  try {
    if (export_csv) {
      await fetchWrapper.download(
        `${BASE_ENDPOINT}/receiver_numbers/${id}/activity/report/export-csv?${queries}`,
        "",
        "Team Report.csv"
      );
      return { data: [] };
    }

    const reports = await fetchWrapper.get(
      `${BASE_ENDPOINT}/receiver_numbers/${id}/activity/report/?${queries}`
    );

    console.log(reports);

    return reports;
  } catch (error) {
    console.log(error);
  }
};

// export const getTeamActivityReports = async ({
//   id,
//   from,
//   to,
//   export_csv,
// }: {
//   id: string;
//   from?: string;
//   to?: string;
//   export_csv?: boolean;
// }) => {
//   let queries = ``;

//   if (!id) return;

//   console.log(id);

//   if (from) queries += `from_date=${from}`;
//   if (to) queries += `&to_date=${to}`;

//   try {
//     if (export_csv) {
//       await fetchWrapper.download(
//         `${BASE_ENDPOINT}/receiver_numbers/${id}/activity/report/export-csv?${queries}`,
//         "",
//         "Team Report.csv"
//       );
//       return { data: [] };
//     }

//     const reports = await fetchWrapper.get(
//       `${BASE_ENDPOINT}//api/numbers/2693/team-members-report/?order_by=satisfaction_rate&custom_start_date=2002-01-01&custom_end_date=2025-01-01&period=custom`
//     );

//     console.log(reports);

//     return reports;
//   } catch (error) {
//     console.log(error);
//   }
// };
