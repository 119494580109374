<template>
  <v-card flat class="modal-card grey-dark text-left" width="500">
    <img
      class="pointer close-modal"
      src="../../../assets/Images/close.svg"
      @click="$emit('closeModal')"
    />
    <img
      src="../../../../public/assets/Images/engagements.png"
      alt=""
      height="180%"
      width="100%"
      contain
      class="out-of-of-intro"
      draggable="false"
    />
    <div class="py-5 pa-6">
      <p class="add-modal-title mb-2 text-center">
        Tell us more about your business to allow us serve you better
      </p>
      <p class="set-up-success-subtitle text-center description">
        To tailor our services more closely to your needs, kindly provide some
        additional details about your business. It will only take a few minutes.
      </p>

      <div class="d-flex justify-center mt-10">
        <v-btn
          class="filled-primary-color"
          height="45"
          flat
          @click="goToOnboard"
        >
          Provide business details
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useUtilsStore } from "../../../stores";

const emit = defineEmits(["closeModal"]);

const router = useRouter();
const utilStore = useUtilsStore();
const goToOnboard = () => {
  utilStore.isNewBusiness = false;
  emit("closeModal");
  router.push("/managers/engagement");
};
</script>
<style scoped>
.close-modal {
  position: absolute;
  right: 16px;
  top: 17px;
}
</style>