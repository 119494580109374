import geohash from "ngeohash";
import { shake256 } from "js-sha3";

interface Coordinates {
  latitude: number;
  longitude: number;
  altitude: number | null;
  accuracy: number;
  altitudeAccuracy: number | null;
  heading: number | null;
  speed: number | null;
}
interface Position {
  coords: Coordinates;
  timestamp: number;
}

const encodeGeolocation = (latitude: number, longitude: number) => {
  try {
    const encoded = geohash.encode(latitude, longitude);
    return encoded;
  } catch (error) {
    return "";
  }
}
export async function getGeolocation(): Promise<string> {
  try {
    const pos = await new Promise<Position>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    return encodeGeolocation(pos.coords.latitude, pos.coords.longitude);
  } catch (error) {
    return "";
  }
}

export function hashTimezone() {
  return shake256(Intl.DateTimeFormat().resolvedOptions().timeZone, 40);
}
