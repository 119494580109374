export const processAddons = (addonList: any) => {
    const processedAddons = addonList.map((addon: any) => {
        let title = addon.title;
        let description = addon.description;
        let isPurchased = addon.isPurchased;
        let subscribedId = addon.subscribedId;
        let cost = addon.standard_cost;
        let unit = "";
        let id = addon.id;
        let image = "";
        let to = "";
        let bannerImage = "";
        let subDetails: {
            title: string;
            description: string;
            icon: string;
        }[] = [];
        let type = addon.addon_type;
        let charges: {
            cost: number | string;
            unit: string;
        }[] = [];

        switch (addon.title) {
            case "Email Hosting":
                image = "/assets/Images/icons/email-hosting.svg";
                to = "/managers/add-ons/email-domain";
                bannerImage = "/assets/Manager/addons/voice-otp/banner.png";
                break;
            case "Knowledge Base Addon":
                image = "/assets/Images/icons/knowledge-base.svg";
                to = "/managers/add-ons/knowledge-base";
                bannerImage = "/assets/Manager/addons/voice-otp/banner.png";
                break;
            case "Website Live Call Addon":
                image = "/assets/Images/icons/live-widget.svg";
                to = "/managers/add-ons/live-call-widget";
                bannerImage = "/assets/Manager/addons/voice-otp/banner.png";
                break;
            case "Voice OTP Addon":
                image = "/assets/Images/icons/voice-otp.svg";
                to = "/managers/add-ons/voice-otp";
                if (!description)
                    description =
                        "This add-on will enable you to send automated one-time passwords to your customers for verification purposes via voice calls.";
                cost = 1.5;
                unit = "/OTP request";
                bannerImage = "/assets/Manager/addons/voice-otp/banner.png";
                subDetails = [
                    {
                        title: "Enhanced Security",
                        description:
                            "Voice OTPs offer a stronger layer of security by Reducing Risk of Interception.",
                        icon: "/assets/Manager/addons/voice-otp/realtime-monitoring.svg",
                    },
                    {
                        title: "Faster Verification",
                        description:
                            "Voice calls can be delivered very quickly, potentially leading to faster verification times compared to SMS messages that may experience delays.",
                        icon: "/assets/Manager/addons/voice-otp/continuous-improvement.svg",
                    },
                ];
                charges = [
                    {
                        cost: "current call rate",
                        unit: "/call duration",
                    },
                    {
                        cost: "₦1.5",
                        unit: "/OTP request",
                    },
                ];
                break;
            case "Text To Speech Addon":
                image = "/assets/Images/icons/text-to-speech.svg";
                to = "/managers/add-ons/text-to-speech";
                if (!description)
                    description =
                        "This add-on enables you to send automated message to your customers for campaign and business updates purpose via voice calls.";
                cost = 0.20;
                unit = "/text character";
                bannerImage = "/assets/Manager/addons/text-to-speech/banner.png";
                subDetails = [
                    {
                        title: "Wide Reach",
                        description:
                            "Reach customers who may not have access to text messages or emails, ensuring broader communication.",
                        icon: "/assets/Manager/addons/voice-otp/realtime-monitoring.svg",
                    },
                    {
                        title: "Consistency",
                        description:
                            "Ensure consistent messaging across all communications.",
                        icon: "/assets/Manager/addons/voice-otp/continuous-improvement.svg",
                    },
                ];
                charges = [
                    {
                        cost: "₦0.20",
                        unit: "/ text character",
                    },
                ];
                break;
            case "Sales Campaign Addon":
                image = "/assets/Images/icons/sales-campaign.svg";
                to = "/managers/add-ons/campaign";
                if (!description)
                    description =
                        "This add-on enables you to run call campaigns for lead generation/qualification, customer win-back, market research, payment and all other sales campaign goals you want to achieve.";
                cost = 5000;
                unit = "/ month";
                bannerImage = "/assets/Manager/addons/sales-campaign/banner.png";
                subDetails = [
                    {
                        title: "Wide Reach",
                        description:
                            "Reach customers who may not have access to text messages or emails, ensuring broader communication.",
                        icon: "/assets/Manager/addons/voice-otp/realtime-monitoring.svg",
                    },
                    {
                        title: "Consistency",
                        description:
                            "Ensure consistent messaging across all communications.",
                        icon: "/assets/Manager/addons/voice-otp/continuous-improvement.svg",
                    },
                ];
                charges = [
                    {
                        cost: "₦5,000",
                        unit: "/ month",
                    },
                ];
                break;
            default:
                break;
        }

        return {
            title,
            isPurchased,
            description,
            subscribedId,
            cost,
            id,
            image,
            to,
            bannerImage,
            subDetails,
            charges,
            type,
            unit,
        };
    });
    return processedAddons;
};
