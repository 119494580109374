<template>
  <div v-if="conversation" class="modal-container">
    <div class="modal-header">
      <div class="icon-wrapper">
        <!-- <img src="path_to_icon" alt="icon" /> -->
      </div>
      <h3>Existing Conversation Detected</h3>
      <v-icon
        class="border rounded-sm p-1"
        size="20"
        @click="$emit('closeModal')"
        >mdi-close</v-icon
      >
    </div>
    <div class="modal-body">
      <p>
        We noticed that you already have an ongoing conversation with this
        contact. What would you like to do?
      </p>
      <div class="conversation-details">
        <h4>Conversation details</h4>
        <div class="detail-item">
          <!-- <img src="path_to_topic_icon" alt="Topic Icon" /> -->
          <span>Topic: {{ conversation.title }}</span>
        </div>
        <div class="detail-item">
          <!-- <img src="path_to_owner_icon" alt="Owner Icon" /> -->
          <span>Owner: {{ conversation.created_by.full_name }}</span>
        </div>
        <div class="detail-item">
          <!-- <img src="path_to_date_icon" alt="Date Icon" /> -->
          <span
            >Date Started:
            {{ formatDateShort(conversation.date_created) }}</span
          >
        </div>
        <!-- <div class="participant-icons">
          <img
            v-for="participant in conversation.participants"
            :key="participant.name"
            :src="participant.avatarUrl"
            alt="Participant Avatar"
          />
        </div> -->
      </div>
    </div>
    <button @click="mergeConversations" class="merge-btn" v-if="conversation">
      Merge conversations
    </button>
    <button @click="startNewConversation" class="new-btn">
      Start a new conversation
    </button>
  </div>

  <v-dialog
    v-model="conversationStore.showNewConversationModal"
    transition="dialog-bottom-transition"
  >
    <NewConvo
      @close-modal="conversationStore.showNewConversationModal = false"
    />
  </v-dialog>
</template>

<script lang="ts" setup>
// import { onMounted } from "vue";
import { getExistingContactConversation } from "../../../helpers/queries/conversations";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import {
  useCallStore,
  useConversationStore,
  useUsersStore,
} from "../../../stores";
import { formatDateShort } from "../../../helpers/utils";
import NewConvo from "./left-pane/cards/NewConvo.vue";
import { AMPLITUDE_EVENTS, trackAmplitudeEvent } from "../../../helpers";

const callStore = useCallStore();
const router = useRouter();
const conversationStore = useConversationStore();
const userStore = useUsersStore();
const conversation = ref();

const fetchConversationDetails = async () => {
  const contactId =
    callStore.selectedCallInfo.contact.id ||
    conversationStore.mergeConversationId;
  if (contactId) {
    try {
      const response = await getExistingContactConversation(contactId);
      if (response.status === 200 && response.data) {
        conversation.value = response.data;
      } else {
        conversationStore.showNewConversationModal = true;
        conversationStore.showMergeConversationModal = false;
        conversation.value = null;
      }
    } catch (error) {
      conversation.value = null;
      conversationStore.showNewConversationModal = true;
      conversationStore.showMergeConversationModal = false;
    } finally {
    }
  }
};

const checkUserRoleAndNavigate = () => {
  if (userStore.user_role === "agent") {
    router.push("/agents/conversations");
  } else if (
    userStore.user_role === "manager" ||
    userStore.user_role === "owner"
  ) {
    router.push("/managers/conversations");
  }
};

const mergeConversations = () => {
  conversationStore.selectedChatId = conversation.value.id;
  trackAmplitudeEvent(AMPLITUDE_EVENTS.CLICKED_MERGE_CONVERSATION);
  conversationStore.selectedChat = {
    id: conversation.value?.id,
    title: conversation.value?.title,
    description: "",
    participants: conversation.value?.participants,
    contacts: conversation.value?.contacts,
    time: conversation.value?.date_created,
    status: conversation.value?.status,
    deadline: "",
    type: conversation.value?.type,
    customer_mobile: conversation.value?.customer_mobile || "",
    customer_name: conversation.value?.customer_name || "",
    created_by: {
      id: conversation.value.created_by?.user_id,
      name: conversation.value.created_by?.name,
      personal_email: conversation.value.created_by?.email,
      mobile: conversation.value.created_by?.mobile,
    },
  };
  if (!window.location.href.includes("conversation")) {
    checkUserRoleAndNavigate(); // Navigate based on the user's role
  }
  conversationStore.showMergeConversationModal = false;
};

const startNewConversation = () => {
  conversationStore.showNewConversationModal = true;
  conversationStore.showMergeConversationModal = false;
};

onMounted(fetchConversationDetails);
</script>

<style scoped>
.modal-container {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin: 0 auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-wrapper {
  width: 32px;
  height: 32px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  margin-top: 1rem;
}

.conversation-details {
  margin-top: 1rem;
  background-color: #f0f2f5;
  padding: 16px;
  border-radius: 8px;

  h4 {
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.detail-item img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.participant-icons {
  display: flex;
  margin-top: 1rem;
}

.participant-icons img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1.5rem;
}

.new-btn {
  background-color: #fff;
  color: #1d2739;
  border: 1px solid #d0d5dd;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}
.merge-btn {
  background-color: #09abe5;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
}

/* .merge-btn:hover,
.new-btn:hover {
  background-color: #0056b3;
} */
.no-conversation {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin: 0 auto;
}
.start-btn {
  background-color: #09abe5;
}
</style>
