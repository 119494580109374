import { useRouter } from "vue-router";
import {
    useAddonStore,
    useAuthStore,
    useBusinessStore,
    useUsersStore,
} from "../stores";
import { getUserDetailsByTokenNewImplementation } from "./queries";

export function useFreeLiveCallRedirection() {
    const authStore = useAuthStore();
    const userStore = useUsersStore();
    const businessStore = useBusinessStore();
    const router = useRouter();
    async function handleTokenRedirection(token: string) {
        authStore.token = token;
        const response = await getUserDetailsByTokenNewImplementation();
        userStore.currentUserBusinessLevel = response.data;
        businessStore.businesses = userStore.currentUserBusinessLevel?.profiles;

        setActiveBusiness();
        userStore.user_role = "owner";

        redirectToAppropriatePage();
    }
    function setActiveBusiness() {
        if (businessStore.businesses?.length && !businessStore.activeBusiness) {
            // Sets the first element in the array as the active business if none exists
            businessStore.activeBusiness = businessStore.businesses[0];
        }
    }
    function redirectToAppropriatePage() {
        const freeTrialData =
            userStore.currentUserBusinessLevel?.free_trial_data;

        if (freeTrialData?.is_free_trial_user) {
            if (freeTrialData.is_developer) {
                router.replace("/developers/dashboard");
            } else {
                router.replace("/add-on/live-call-free-widget/welcome");
            }
        }
    }
    return {
        handleTokenRedirection,
    };
}

export const listProgramFeatures = [
    {
        title: "Website Live Call",
        description:
            "Integrate PressOne Live Call to allow your customers talk without leaving your platform.",
        img: "/assets/Images/developer-program/live-call.svg",
        isAvailable: true,
        to: "/developers/live-call-widget",
    },
    {
        title: "Voice OTP",
        description:
            "Integrate PressOne Voice OTP to enable secure voice calls for user authentication.",
        img: "/assets/Images/developer-program/voice-otp.svg",
        isAvailable: true,
        to: "/developers/voice-otp",
    },
    {
        title: "Call SDK",
        description:
            "Have PressOne functionality in your app. Make and receive calls, manage contacts and notes.",
        img: "/assets/Images/developer-program/full-widget.svg",
        isAvailable: false,
        to: "",
    },
];

export const listAvailableAddOneatures = [
    {
        title: "Website Live Call",
        description:
            "This add-on allows customers to initiate real-time calls directly from a website, enhancing customer support through instant communication.",
        img: "/assets/Images/icons/live-widget.svg",
        isSubscribed: true,
        to: "/developers/live-call-widget",
    },
    {
        title: "Voice OTP",
        description:
            "This add-on will enable you to send automated one-time passwords to your customers for verification purposes via voice calls.",
        img: "/assets/Images/icons/voice-otp.svg",
        isSubscribed: true,
        to: "/developer/voice-otp",
    },
];
export const getVoiceOTPId = () => {
    const addonStore = useAddonStore();
    const getLiveCallSub = addonStore.getSubscribedAddons?.find(
        (addon) => addon.subscription_plan?.title === "Voice OTP Addon"
    );
    return getLiveCallSub?.id;
};
