import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";

interface ResponseData {
    [key: string]: any;
}

export const updateDeveloperProfile = async (
    payload: any
): Promise<ResponseData> => {
    let url = `${BASE_API}/api/third-party/developer/register/`;
    return await fetchWrapper.post(url, payload);
};

export const getDeveloperProfile = async (): Promise<ResponseData> => {
    let url = `${BASE_API}/api/third-party/developer/me/`;
    return await fetchWrapper.get(url);
};
