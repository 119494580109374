import { defineStore } from "pinia";
import { Contact, SavedContact } from "../helpers/types/contact";
interface ContactState {
  savedContacts: SavedContact | any;
  allContacts: any[];
  callQueueId: string;
  computedContactsList: any;
  loadingContacts: boolean;
  selectedContact: any;
  selectedContacts: any[];
  totalContacts: number;
  selectedContactAndColor?:
    | {
        gradColors: {
          color1: string;
          color2: string;
        };
        contact: Contact;
      }
    | any;
}

export const useContactStore = defineStore({
  id: "contact",
  state: (): ContactState => ({
    savedContacts: {},
    allContacts: [],
    callQueueId: "",
    computedContactsList: [],
    loadingContacts: false,
    selectedContact: null,
    selectedContacts: [],
    totalContacts: 0,
  }),
  getters: {
    selectedContactIDs: (state: ContactState) => {
      const selectedIDs = [];
      for (let i = 0; i < state.selectedContacts.length; i++) {
        selectedIDs.push(state.selectedContacts[i].id); // Assuming each selected contact has an "id" property
      }
      return selectedIDs;
    },
  },
});
