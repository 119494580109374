import { ResponseData } from "..";
import { BASE_API } from "../constants";
import { fetchWrapper } from "../fetch-wrapper";

export const fetchAllVideo = async (
    role: string,
    link?: string
): Promise<ResponseData> => {
    if (link) return await fetchWrapper.get(link);
    else
        return await fetchWrapper.get(
            `${BASE_API}/api/user/demo-video/?visibility=general,${role}`
        );
};
export const searchDemoVideo = async (
    role: string,
    searchParam: string
): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/user/demo-video/?visibility=general,${role}&title=${searchParam}`
    );
};
