<template>
  <v-row class="px-16 py-5 connection-headers">
    <v-col
      cols="3"
      v-for="title in headers"
      :key="title.title"
      class="py-0 pr-0"
      >{{ title.title }}
    </v-col>
  </v-row>
  <v-row
    v-for="item in numberStore?.numbers"
    :key="item"
    class="connection-data"
  >
    <v-col cols="3" class="py-5 d-flex align-center ga-2 pl-16">
      <img
        src="../../../assets/Images/icons/bullets/green.svg"
        alt=""
        width="10"
        v-if="dialerStore.isRegistered[item.id]?.isConnected"
      />
      <img
        src="../../../assets/Images/icons/bullets/red.svg"
        alt=""
        width="10"
        v-else
      />
      <span>{{
        dialerStore.isRegistered[item.id]?.isConnected
          ? "Connected"
          : "Disconnected"
      }}</span></v-col
    >
    <v-col cols="3" class="py-5 pl-10">
      <span> {{ item?.business_number?.label || "Business Number" }}</span>
      <v-icon
        class="text-primary"
        size="20"
        v-if="
          numberStore.activeNumber?.business_number.phone_number ===
          item.business_number.phone_number
        "
        >mdi-check-circle
      </v-icon></v-col
    >
    <v-col cols="3" class="connection-number py-5">
      {{ formatPhoneNumberPresentation(item?.business_number?.phone_number) }}
    </v-col>
    <v-col cols="3" class="py-5">
      <div
        class="connected-action py-3 px-5 action d-flex ga-2"
        v-if="dialerStore.isRegistered[item.id]?.isConnected"
      >
        <span></span>
      </div>
      <span
        v-else-if="
          !dialerStore.isRegistered[item.id]?.isConnected &&
          dialerStore.isRegistered[item.id]?.isConnecting
        "
        class="connect-action py-3 px-5 action pointer"
        >Connecting
        <v-progress-circular indeterminate size="20"></v-progress-circular>
      </span>
      <span
        v-else
        class="connect-action py-3 px-5 action pointer ml-3"
        @click="reconnectPhone(item.id)"
        >Connect
      </span>
    </v-col>
    <v-divider></v-divider>
  </v-row>
  <v-row
    class="connection-data mb-1"
    v-if="dialerStore.getLiveCallConnectionStatus"
  >
    <v-col cols="3" class="py-5 d-flex align-center ga-2 pl-16">
      <img
        src="../../../assets/Images/icons/bullets/green.svg"
        alt=""
        width="10"
        v-if="dialerStore.getLiveCallConnectionStatus?.isConnected"
      />
      <img
        src="../../../assets/Images/icons/bullets/red.svg"
        alt=""
        width="10"
        v-else
      />
      <span>{{
        dialerStore.getLiveCallConnectionStatus?.isConnected
          ? "Connected"
          : "Disconnected"
      }}</span></v-col
    >
    <v-col cols="3" class="py-5 pl-10">
      <span> Website Live Widget</span></v-col
    >
    <v-col cols="3" class="connection-number py-5"> Widget </v-col>
    <v-col cols="3" class="py-5">
      <div
        class="connected-action py-3 px-5 action d-flex ga-2"
        v-if="dialerStore.getLiveCallConnectionStatus?.isConnected"
      >
        <span></span>
      </div>
      <span
        v-else-if="
          !dialerStore.getLiveCallConnectionStatus?.isConnected &&
          dialerStore.getLiveCallConnectionStatus?.isConnecting
        "
        class="connect-action py-3 px-5 action pointer"
        >Connecting
        <v-progress-circular indeterminate size="20"></v-progress-circular>
      </span>
      <span
        v-else
        class="connect-action py-3 px-5 action pointer ml-3"
        @click="reconnectPhone(Object.keys(dialerStore.getNoTrunkCreds)[0])"
        >Connect
      </span>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useDialerStore, useNumberStore } from "../../../stores";
import { formatPhoneNumberPresentation } from "../../../helpers";
import { notify } from "@kyvg/vue3-notification";
import { useConnectPhoneToSip } from "../../../helpers/dialer";
export default defineComponent({
  setup() {
    const numberStore = useNumberStore();
    const dialerStore = useDialerStore();
    const itemsPerPage = numberStore?.numbers?.length;
    const headers = [
      {
        title: "Connection Status",
        key: "Name",
      },
      { title: "Phone Number Name" },
      { title: "Phone Number" },
      { title: "Connection Status" },
      //   { title: "Connection Time" },
      //   { title: "Last Connected" },
      // { title: "Connection Status" },
    ];
    const getPressoneInstance: any = inject("getPressoneInstance");
    const selectedInstanceKey = numberStore.currentUserReceiverKey;

    const { reconnectPhone, disconnectPhoneWhenOffline } =
      useConnectPhoneToSip();

    const connectPhone = async ({ id }: any) => {
      console.log(navigator.onLine);

      if (!navigator.onLine) {
        notify({
          text: "You are currently not online at the moment. Kindly connect to the internet and retry",
        });
        return;
      }
      dialerStore.isRegistered[id] = {
        isConnected: false,
        isConnecting: true,
      };

      if (getPressoneInstance()) {
        try {
          await getPressoneInstance()?.start(selectedInstanceKey, [id]);
          getPressoneInstance().onRegistered = () => {
            dialerStore.isRegistered[id] = {
              isConnected: true,
              isConnecting: false,
            };
          };
          getPressoneInstance().onRegistrationFail = () => {
            dialerStore.isRegistered[id] = {
              isConnected: false,
              isConnecting: false,
            };
          };
          getPressoneInstance().onUnregistered = () => {
            dialerStore.isRegistered[id] = {
              isConnected: false,
              isConnecting: false,
            };
            getPressoneInstance().state[id].userAgent.register();
          };
        } catch (e) {
          notify({
            text: `Encountered an error connecting number`,
            title: "Error connecting..",
            type: "error",
          });
          dialerStore.isRegistered[id] = {
            isConnected: false,
            isConnecting: false,
          };
        }
      }
    };
    const disconnectPhone = async ({ id }: any) => {
      getPressoneInstance().state[id].userAgent.stop();
      getPressoneInstance().onUnregistered = () => {
        dialerStore.isRegistered[id] = {
          isConnected: false,
          isConnecting: false,
        };
      };
    };
    // watch(dialerStore.isRegistered, (newValue, oldValue) => {
    //     console.log(newValue);

    //       for (const key in newValue) {
    //         if (newValue[key].isConnected === false) {
    //           console.log('Device with key', key, 'is disconnected');
    //            connectPhone (key)
    //         }
    //       }
    //     }, { deep: true });
    return {
      headers,
      numberStore,
      formatPhoneNumberPresentation,
      dialerStore,
      connectPhone,
      disconnectPhoneWhenOffline,
      reconnectPhone,
      disconnectPhone,
      itemsPerPage,
    };
  },
});
</script>
<style scoped>
.connection-data {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1d2739;
}
.connection-number {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475367;
}
.action {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  box-shadow: 2px -2px 12px 0px #ffffff4d inset;
  border-radius: 8px;
}
/* .connected-action { */
/* color: #34c759; */
/* background: #ebfaef; */
/* } */
.connect-action {
  color: #00aeef;
  background: #edfaff;
}
.connection-headers {
  color: #344054;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.4px;
  text-align: left;
  background: #f7f9fc;
}
</style>