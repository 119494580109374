import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API } from "../constants";
import { NumberProvisionPayload } from "..";
import { useUsersStore } from "../../stores";
interface ResponseData {
    [key: string]: any;
}

const getUserId = () => {
    const userStore = useUsersStore();
    return userStore?.currentUserBusinessLevel?.id;
};

export const getAvailableNumbers = async (
    country_code: string,
    numberType: string,
    isTollFree?: boolean,
    isSpecialNumber?: boolean
): Promise<ResponseData> => {
    if (!isTollFree) {
        isTollFree = false;
    }

    if (!isSpecialNumber) {
        isSpecialNumber = false;
    }

    return await fetchWrapper.get(
        `${BASE_API}/api/available-numbers/?country=${country_code}&number_type=${numberType}&is_toll_free=${isTollFree}&is_special_number=${isSpecialNumber}&page_size=100`
    );
};

export const provisionNumberToUser = async (
    payload: NumberProvisionPayload
): Promise<ResponseData> => {
    return await fetchWrapper.post(
        `${BASE_API}/api/telephony/provision-number/`,
        payload
    );
};

export const isCardExist = async (): Promise<ResponseData> => {
    return await fetchWrapper.get(
        `${BASE_API}/api/users/${getUserId()}/cards/`
    );
};
