import { defineStore, getActivePinia } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    requestLoginOTP,
    ISignIn,
    getPendingNumberPayment,
    handleFetchUserDetails,
    trackAmplitudeEvent,
    encryptKey,
    verifyEncryptedLoginToken,
    getUserDetailsByTokenNewImplementation,
    logoutAndClearCookies,
} from "../helpers";
import {
    useUsersStore,
    useUtilsStore,
    useNumberStore,
    useBusinessStore,
    useDialerStore,
} from ".";
import router from "../router";
import { setTrackedUser, setUserProperties } from "../helpers";
import { AMPLITUDE_EVENTS } from "../helpers/integrations/analytics/events";
import * as Sentry from "@sentry/vue";
import { encryptToken } from "../helpers/encryptionKeys";
import logger from "../helpers/logger";
import { notify } from "@kyvg/vue3-notification";

interface AuthState {
    mobile: string | null;
    token: string | null;
    otpSent: boolean;
    returnUrl: string | null;
    email: string | null;
    loading: boolean;
    authError: string;
    selectedPlanId?: number | string;
    couponCode?: string;
    scheme: string; // otp sender type
    encryptionData: any;
    isLoggingOut: boolean;
}

export const useAuthStore = defineStore({
    id: "auth",
    state: (): AuthState => ({
        // initialize state from local storage to enable user to stay logged in
        mobile: null,
        otpSent: false,
        token: null,
        email: null,
        returnUrl: null,
        loading: false,
        authError: "",
        scheme: "",
        encryptionData: null,
        couponCode: "",
        isLoggingOut: false,
    }),
    getters: {
        isAuthenticated: (state: AuthState) => !!state.token,
    },
    actions: {
        async getOTP(payload: ISignIn) {
            this.authError = "";
            this.loading = true;
            this.mobile = payload.mobile;
            const responseKeyData = await encryptKey({ login_id: this.mobile }); // get keys and session id
            this.encryptionData = responseKeyData.data;
            const response = await requestLoginOTP(payload);
            this.email = response.data.email;
            return response; // we intentionally did not catch error
        },

        async login(payload: any) {
            this.loading = true;
            this.authError = "";
            try {
                const encryptedToken = encryptToken(
                    this.encryptionData.public_key,
                    payload.token
                ); // encrypt token
                // const { data, headers } = await verifyLoginToken(payload);
                const requestPayload = {
                    // new encryption payload
                    login_id: payload.mobile,
                    auth_session_id: this.encryptionData.auth_session_id,
                    otp: encryptedToken, // replaces the former token
                };
                const { data } = await verifyEncryptedLoginToken(
                    requestPayload
                );
                // console.log(data);
                this.token = data.token;

                const currentUser =
                    await getUserDetailsByTokenNewImplementation().then(
                        (res: any) => res.data
                    );
                this.mobile = currentUser.mobile_phone;

                const businessStore = useBusinessStore();
                const userStore = useUsersStore();
                const utilsStore = useUtilsStore();
                const numberStore = useNumberStore();

                userStore.currentUserBusinessLevel = currentUser;
                localStorage.setItem("token", data.token);

                businessStore.businesses = userStore.userProfile;
                if (
                    businessStore.businesses?.length &&
                    !businessStore.activeBusiness
                ) {
                    // sets the first element in the array to the active number if no active number exists
                    businessStore.activeBusiness = businessStore.businesses[0];
                }

                setTrackedUser(
                    userStore?.currentUserBusinessLevel?.personal_email
                ); // tracks user using unique id i.e email
                setUserProperties({
                    email: userStore?.currentUserBusinessLevel?.personal_email,
                    phone: userStore.currentUserBusinessLevel?.mobile,
                    first_name: userStore?.currentUserBusinessLevel?.first_name,
                    last_name: userStore?.currentUserBusinessLevel?.last_name,
                });
                trackAmplitudeEvent(AMPLITUDE_EVENTS.LOGIN); // tracks login event
                if (utilsStore.signInSource) {
                    trackAmplitudeEvent(
                        `User Signin - ${utilsStore.signInSource}`
                    ); // tracks the source of the login
                }
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: userStore?.currentUserBusinessLevel
                            ?.personal_email,
                    });
                });
                try {
                    //check if the user has a receiver

                    if (
                        userStore.currentUserBusinessLevel?.profiles?.length < 1
                    ) {
                        // check if user has pending number payment

                        const pendingSetupResponse =
                            await getPendingNumberPayment();
                        const pendingNumberSetup = pendingSetupResponse.data;

                        utilsStore.setSetUpPayload(pendingNumberSetup?.data);
                        if (pendingNumberSetup?.data?.length) {
                            userStore.user_role = "owner";
                            console.log("Navigating to provision-number page");
                            router.push("/managers/provision-number");
                        } else {
                            router.push("/complete-signup");
                        }
                    } else {
                        if (
                            userStore.currentUserBusinessLevel?.profiles
                                ?.length > 0
                        ) {
                            // assign the user_role for the user to the role of the first receiver
                            userStore.user_role =
                                userStore.currentUserBusinessLevel?.profiles[0].role;

                            if (
                                userStore.user_role === "owner" ||
                                userStore.user_role === "manager"
                            ) {
                                await handleFetchUserDetails();
                                await numberStore.getUserPhonesFromBusiness();
                                if (this.returnUrl?.includes('call-record-details')) //  redirect to record page if user is coming from there
                                    router.push(this.returnUrl)
                                else router.push("/managers/activities");
                            } else {
                                if (
                                    !userStore.currentUserBusinessLevel
                                        ?.last_active &&
                                    !businessStore.activeProfile
                                        ?.has_filled_engagement_form
                                )
                                    // for first time agents also
                                    router.push("/agents/engagement");
                                // redirect to engagement page
                                else {
                                    if (this.returnUrl?.includes('call-record-details')) //  redirect to record page if user is coming from there
                                        router.push(this.returnUrl)
                                    else router.push("/");
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.error(
                        "Error fetching pending number payment:",
                        error
                    );
                    logger.error(
                        error,
                        "Error occured during Authentication LN:163"
                    );
                    // Theres no point redirecting on error
                    // router.push("/");
                }
            } catch (error: any) {
                this.loading = false;

                let errorMsg = "";
                if (error) {
                    notify({
                        type: "error",
                        text: error,
                    });
                } else {
                    notify({
                        type: "error",
                        text: "Something went wrong, please try again",
                    });
                }
                this.loading = false;
                this.authError = errorMsg;
                throw errorMsg;
            }
            this.encryptionData = null;
        },
        clearStores() {
            //clear stores
            const userStore = useUsersStore();
            userStore.$reset();
            const authStore = useAuthStore();
            authStore.$reset();
            const utilsStore = useUtilsStore();
            utilsStore.$reset();
            const numbersStore = useNumberStore();
            numbersStore.$reset();
            const dailerStore = useDialerStore();
            dailerStore.$reset();

            // map through that list and use the **$reset** fn to reset the state
            (getActivePinia() as any)._s.forEach((store: any) =>
                store.$reset()
            );
        },
        async CLEAR_X_REFRESH_TOKEN() {
            try {
                await logoutAndClearCookies();
            } catch (error) {
                logger.error(error, "refresh logout token error");
            }
        },
        async logout() {
            this.isLoggingOut = true;
            this.token = null;
            this.clearStores();
            this.mobile = null;
            Sentry.setUser(null);
            const token = localStorage.getItem("token");
            if (token) {
                localStorage.removeItem("token");
                localStorage.removeItem("users");
                localStorage.removeItem("auth");
                // localStorage.removeItem("utils");
                localStorage.removeItem("numbers");
                localStorage.removeItem("dialer");
            }
            document.cookie.split(";").forEach(function (cookie) {
                document.cookie = cookie
                    .replace(/^ +/, "")
                    .replace(
                        /=.*/,
                        "=;expires=" + new Date().toUTCString() + ";path=/"
                    );
            });
            localStorage.clear();
            sessionStorage.clear(); // clear

            await router.push("/sign-in");
            this.isLoggingOut = false;
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
