import { useAuthStore } from "../stores/index";
import { BASE_API, AUTH_EXCLUDE_LIST } from "./constants";
import { getGeolocation, hashTimezone } from "./integrations/geofencing";
import logger from "./logger";

// Define custom error classes
class AuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AuthError";
  }
}
// class PermissionError extends Error {}

// Define the type for the response data
interface ResponseData {
  [key: string]: any;
}

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  patch: request("PATCH"),
  download: downloadRequest,
  public_post: publicRequest("POST"),
  public_get: publicRequest("GET"),
  formDataPost: formDataRequest("POST"),

  getRecommendation: request("GET", true), // for recommendation endpoint (get and patch since the exist as public endpoint in AUTH_EXCLUDE_LIST already)
  patchRecommendation: request("PATCH", true),
  downloadExcel: downloadExcelRequest
};

function formDataRequest(method: string) {
  return async (url: string, formData: FormData) => {
    const requestOptions: RequestInit = {
      method: method,
      body: formData,
      headers: await authHeader(url),
    };

    return fetch(url, requestOptions).then(handleResponse);
  };
}

function request(method: string, needAuthentication?: boolean) {
  return async (
    url: string,
    body?: any, // body can be undefined
    { credentials, geofence }: { credentials?: string; geofence?: boolean } = {}
  ) => {
    const requestOptions: RequestInit = {
      method: method,
      headers: await authHeader(url, needAuthentication),
      // headers: { Accept: "application/json" },
    };
    if (body) {
      if (requestOptions.headers instanceof Headers) {
        requestOptions.headers.set("Content-Type", "application/json");
      }
      requestOptions.body = JSON.stringify(body);
    }

    if (credentials) {
      requestOptions.credentials = credentials as RequestCredentials; // Type assertion
    }
    if (geofence) {
      const geoLocation = await getGeolocation();

      if (requestOptions.headers instanceof Headers) {
        if (geoLocation) {
          requestOptions.headers?.set("X-GHSH", geoLocation); // only send if geoLocation is allowed
        }
        requestOptions.headers?.set("X-TZ", hashTimezone()); // sends hashed TimeZone
      }
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

function publicRequest(method: string) {
  return (url: string, body?: any) => {
    const requestOptions: RequestInit = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    if (body) {
      if (requestOptions.headers instanceof Headers) {
        requestOptions.headers.set("Content-Type", "application/json");
      }
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

async function downloadRequest(url: string, body: any, filename: string) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: await authHeader(url),
  };

  if (body) {
    if (requestOptions.headers instanceof Headers) {
      requestOptions.headers.set("Content-Type", "application/json");
    }
    requestOptions.body = JSON.stringify(body);
  }

  return fetch(url, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob], { type: "text/csv" })
      );
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}

async function downloadExcelRequest( // new format are now in excel. Starting with logs
  url: string,
  body: any,
  name: string
) {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: await authHeader(url),
  };

  if (body) {
    if (requestOptions.headers instanceof Headers) {
      requestOptions.headers.set("Content-Type", "application/json");
    }
    requestOptions.body = JSON.stringify(body);
  }

  return fetch(url, requestOptions)
    .then((response) => {
      const contentDisposition = response.headers.get("content-disposition");

      let extractedFilename: string
      // Extract filename from Content-Disposition header or use constructed file name
      if (contentDisposition) {
        extractedFilename = contentDisposition.split('filename=')[1];
      } else extractedFilename = name!

      return response.blob().then((blob) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([blob], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
        );
        const a = document.createElement("a");
        a.href = downloadUrl;

        a.setAttribute("download", extractedFilename);
        document.body.appendChild(a);

        a.click();
        a.remove();
      });
    })
    .catch((error) => {
      logger.error("File download failed:", error);
    });
}



// Helper functions

async function authHeader(url: string, needAuthentication?: boolean) {
  try {
    const authStore = useAuthStore();
    const { token } = authStore;
    const needsAuth =
      !AUTH_EXCLUDE_LIST.includes(new URL(url).pathname) || needAuthentication;
    if (needsAuth) {
      // Check if the token is expired
      const isExpired = isTokenExpired(token);
      if (isExpired) {
        try {
          // Attempt to refresh the token

          const { data } = await fetchWrapper.post(
            `${BASE_API}/api/auth/token/refresh/`,
            null,
            {
              credentials: "include",
            }
          );
          authStore.token = data!.token;
        } catch (err) {
          console.warn(err, "Unable to refresh expired token.");
          authStore.logout();

          throw new AuthError("Unable to refresh expired token."); // fix No error message issue
        }

        return {
          Authorization: `Bearer ${authStore.token}`,
        };
      }

      const headers = new Headers();
      headers.set("Authorization", `Bearer ${authStore.token}`);

      return headers;
    } else {
      return undefined;
    }
  } catch (error) {
    console.log(error);
  }
}

async function handleResponse(response: Response) {
  if (response.status === 204) {
    return {
      data: null, // No data for 204 No Content response.
      status: response.status,
    };
  }

  const isJson = response.headers
    ?.get("content-type")
    ?.includes("application/json");
  const data = isJson ? ((await response.json()) as ResponseData) : null;

  if (!response.ok) {
    //check if response is a json and handle accordingly
    let error;

    // Handle 401 and 403 specifically
    if (response.status === 401) {
      error = "Unauthorized access. Please log in again.";
    } else if (response.status === 403) {
      error = "You do not have permission to perform this action.";
    } else {
      // General error handling for other status codes
      error = data
        ? data.message
          ? data.message
          : JSON.stringify(data)
        : response.status;
    }

    return Promise.reject(error);
  }

  return {
    data,
    status: response.status,
  };
}

const decodeToken = (token: string) => {
  try {
    if (token.split(".").length !== 3 || typeof token !== "string") {
      return null;
    }

    const payload = token.split(".")[1];

    const base64 = payload.replace("-", "+").replace("_", "/");
    const decoded = JSON.parse(atob(base64));

    return decoded;
  } catch (error) {
    return null;
  }
};

const isTokenExpired = (token: string | null) => {
  const decodedToken = decodeToken(token as string);
  if (decodedToken && decodedToken.exp) {
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    const now = new Date();
    now.setSeconds(now.getSeconds() + 10);
    return expirationDate.valueOf() < now.valueOf();
  } else {
    return true;
  }
};
