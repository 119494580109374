import Engage from "@engage_so/js";
import { ENGAGE_PRIVATE_KEY, ENGAGE_PUBLIC_KEY } from "../..";

export enum ENGAGE_EVENTS {
  SELECTED_SOLO_PLAN = "Selected Solo Plan",
  SELECTED_STANDARD_PLAN = "Selected Standard Plan",
  SELECTED_GROWTH_PLAN = "Selected Growth Plan",
  SELECTED_INTERNATIONAL_PLAN = "Selected International Plan",
  SELECTED_SOLO_QUARTERLY_PLAN = "Selected Solo Quaterly Plan",
  SELECTED_STANDARD_QUARTERLY_PLAN = "Selected Solo Quaterly Plan",
  SELECTED_GROWTH_QUARTERLY_PLAN = "Selected Growth Quaterly Plan",
  SELECTED_INTERNATIONAL_QUARTERLY_PLAN = "Selected International Quaterly Plan",
  SELECTED_SOLO_ANNUAL_PLAN = "Selected Solo Anual Plan",
  SELECTED_STANDARD_ANNUAL_PLAN = "Selected Standard Anual Plan",
  SELECTED_GROWTH_ANNUAL_PLAN = "Selected Growth Anual Plan",
  SELECTED_INTERNATIONAL_ANNUAL_PLAN = "Selected International Anual Plan",
  FILLED_BUSINESS_INFO = "Save Businnes Information On Signup",
  TOGGLE_CALL_PANEL = "Toggle call modal",
  COMPLETED_ENTIRE_FLOW = "Completed Setup Flow",
}

export const planEventMap = {
  "solo plan": ENGAGE_EVENTS.SELECTED_SOLO_PLAN,
  "standard plan": ENGAGE_EVENTS.SELECTED_STANDARD_PLAN,
  "growth plan": ENGAGE_EVENTS.SELECTED_GROWTH_PLAN,
  "international plan": ENGAGE_EVENTS.SELECTED_INTERNATIONAL_PLAN,
  "solo plan (quarterly)": ENGAGE_EVENTS.SELECTED_SOLO_QUARTERLY_PLAN,
  "standard plan (quarterly)": ENGAGE_EVENTS.SELECTED_STANDARD_QUARTERLY_PLAN,
  "growth plan (quarterly)": ENGAGE_EVENTS.SELECTED_GROWTH_QUARTERLY_PLAN,
  "international (quarterly)":
    ENGAGE_EVENTS.SELECTED_INTERNATIONAL_QUARTERLY_PLAN,
  "solo plan (annual)": ENGAGE_EVENTS.SELECTED_SOLO_ANNUAL_PLAN,
  "standard plan (annual)": ENGAGE_EVENTS.SELECTED_STANDARD_ANNUAL_PLAN,
  "growth plan (annual)": ENGAGE_EVENTS.SELECTED_GROWTH_ANNUAL_PLAN,
  "international (annual)": ENGAGE_EVENTS.SELECTED_INTERNATIONAL_ANNUAL_PLAN,
};

function initializeEngage() {
  Engage.init({
    key: ENGAGE_PUBLIC_KEY,
    secret: ENGAGE_PRIVATE_KEY,
  });
}

export const engageTrack = (
  user_id: string,
  event: string,
  properties = {}
) => {
  if (!user_id) return console.error("User id is required to track event");
  initializeEngage();
  Engage.track(user_id, {
    event,
    properties: { ...properties },
  });
};

export const engageIdentify = async (user_id: string, properties = {}) => {
  initializeEngage();
  await Engage.identify({
    id: user_id,
    ...properties,
  });
};

export const engageIdentifyTrack = async (
  user_id: string,
  event: string,
  properties = {}
) => {
  initializeEngage();
  await Engage.identify({
    id: user_id,
    ...properties,
  });
  engageTrack(user_id, event, properties);
};
