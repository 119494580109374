import Pusher from "pusher-js";
// import { computed } from "vue";
import { BASE_API } from "./constants";
// import { useConversationStore } from "../stores";

const appKey = "debe1f35fb3732674bc9";
const cluster = "mt1";

export const createPusherInstance = (
  accessToken: string | null,
  conversationId: string | null
) => {
  //   const conversationStore = useConversationStore();

  //   const authEndpoint = computed(() => {
  //     const channelId = conversationStore && conversationStore.selectedChatId;
  //     return `${BASE_API}/api/business/conversations/${channelId}/pusher/auth/`;
  //   });

  return new Pusher(appKey, {
    cluster: cluster,
    channelAuthorization: {
      transport: "ajax",
      endpoint: `${BASE_API}/api/business/conversations/${conversationId}/pusher/auth/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
};
