import { notify } from "@kyvg/vue3-notification";
import {
    AMPLITUDE_EVENTS,
    getUserDetailsByToken,
    getUserDetailsByTokenNewImplementation,
    trackAmplitudeEvent,
    updateFeedback,
    validateMobile,
} from ".";
import { useUsersStore, useBusinessStore, useAuthStore } from "../stores";
import { fetchTeamMemberDetails } from "./queries/teamMembers";
import logger from "./logger";
import { useRouter } from "vue-router";

export const findRecommendedPlan = (
    teamMembers: number,
    isInternational: boolean,
    billingPeriod: string
) => {
    if (isInternational) {
        if (billingPeriod == "Quarterly") {
            return "International (Quarterly)";
        } else if (billingPeriod == "Annually") {
            return "International (Annual)";
        }
        return "International Plan";
    } else {
        if (teamMembers == 1) {
            if (billingPeriod == "Quarterly") {
                return "Solo Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Solo Plan (Annual)";
            }

            return "Solo Plan";
        } else if (teamMembers > 1 && teamMembers <= 3) {
            if (billingPeriod == "Quarterly") {
                return "Standard Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Standard Plan (Annual)";
            }

            return "Standard Plan";
        } else if (teamMembers > 3) {
            if (billingPeriod == "Quarterly") {
                return "Growth Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Growth Plan (Annual)";
            }
            return "Growth Plan";
        }
    }
};

export const handleFetchUserDetails = async () => {
    try {
        const res = await getUserDetailsByToken();
        const response = await getUserDetailsByTokenNewImplementation();
        const businessStore = useBusinessStore();
        const userStore = useUsersStore();
        userStore.currentUser = res.data;
        userStore.currentUserBusinessLevel = response.data;
        if (businessStore.businesses?.length) {
            // checks the current active business and updates the exact active business on refresh
            const activeBusinessId = businessStore.activeBusiness?.business.id;
            businessStore.activeBusiness = businessStore?.businesses.find(
                (business: any) => business.business.id == activeBusinessId
            )!;
        }
        userStore.user_role = businessStore.activeBusiness?.role as string;
    } catch (error) {
        logger.error(error, "Error while Fetching User details");
    }
};
export const updateFeedbackSheet = async () => {
    await updateFeedback();
};
export const getPermissions = async () => {
    const businessStore = useBusinessStore();
    let userId = businessStore.currentUserId as number;
    try {
        const res = await fetchTeamMemberDetails(userId);
        return res.data.permission;
    } catch (error) {
        notify({
            text: "Unable to get permissions",
            type: "error",
        });
    }
};

export const handlePartnerSigin = async (callbackFunction: any) => {
    const authStore = useAuthStore();
    const router = useRouter();
    try {
        let queryString = window.location.href.split("?")[1];
        if (!queryString) return;

        let paramsArray = queryString.split("&");
        let queryParams = {} as any;

        for (let param of paramsArray) {
            let [key, value] = param.split("=");
            queryParams[key] = decodeURIComponent(value.replace(/\+/g, " "));
        }

        let payload: { mobile?: string; scheme?: string } = {
            scheme: "SMS",
        };
        if (queryParams.id) {
            payload = {
                mobile: validateMobile(queryParams.id),
            };
        }

        if (queryParams.trxref && authStore.mobile) {
            // update the payload with mobile number from signup page if redirected from paystack
            payload.mobile = authStore.mobile as string;
        }

        if (payload.mobile) {
            authStore.mobile = payload.mobile;
        }

        if (queryParams?.ref === "partner") {
            if (queryParams?.code === "WGH") {
                trackAmplitudeEvent(
                    AMPLITUDE_EVENTS.WGH_REFERALL_SIGNUP,
                    queryParams
                );
            }
        }
        await callbackFunction(payload);
    } catch (error) {
        console.log(error);
        logger.error(error, "Error on signing in partners");
        router.push("/sign-in");
    }
};

export function removeDialCode(phoneNumber: string) {
    // Define an array of common international dial codes
    const dialCodes = ["+234", "+1", "+254", "234", "1", "254"];
    // Loop through the dial codes and check if the phone number starts with any of them
    for (const code of dialCodes) {
        if (phoneNumber.startsWith(code)) {
            // If a dial code is found, return the phone number with the dial code removed
            return phoneNumber.slice(code.length);
        }
    }
    // If no dial code is found, return the original phone number
    return phoneNumber;
}
