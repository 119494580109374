import Pusher from "pusher-js";
import { useAuthStore } from "../stores";
import { createPusherInstance } from "./pusherService";

import { useConversationStore } from "../stores";
import { onMounted, ref, watch } from "vue";

let authStore: ReturnType<typeof useAuthStore> | null = null;
let conversationStore: ReturnType<typeof useConversationStore> | null = null;

const accessToken = ref<string | null>("");

export const guestUserToken = (conversationId: string) => {
  const invitationId = localStorage.getItem("conversationinvitationId");
  const generalBase = btoa(`${conversationId}:${invitationId}`);

  return generalBase;
};

export let pusher = ref<Pusher | null>(null);

onMounted(() => {
  authStore = useAuthStore();
  conversationStore = useConversationStore();
  accessToken.value = authStore.token;
  pusher.value = createPusherInstance(
    accessToken.value,
    conversationStore.selectedChatId
  );
});

watch(
  () => conversationStore && conversationStore.selectedChatId,
  (newConversationId) => {
    const accessToken = authStore && authStore.token;
    pusher.value = createPusherInstance(accessToken, newConversationId);
  }
);

export async function fetchData(url: any, token: string | null) {
  try {
    // const conversationId = route.query.conversation_id as string;
    // const invitationId = route.query.invitation_id as string;
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
}

export async function postData(url: any, token: string | null, payload: any) {
  try {
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
}

export function getStatusIconAndColor(status: string): {
  icon: string;
  backgroundColor: string;
} {
  switch (status) {
    case "open":
      return {
        icon: "/assets/Images/icons/conversations/open.svg",
        backgroundColor: "#F0F2F5", // green color
      };
    case "closed":
      return {
        icon: "/assets/Images/icons/conversations/conversation-closed.svg",
        backgroundColor: "#34C75933", // orange color
      };
    case "on-hold":
      return {
        icon: "/assets/Images/icons/conversations/on-hold.svg",
        backgroundColor: "#FFA50033", // pink color
      };
    default:
      return {
        icon: "/assets/Images/icons/conversations/open.svg",
        backgroundColor: "#F0F2F5", // green color
      };
  }
}
