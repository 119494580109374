import { fetchWrapper } from "../../helpers/fetch-wrapper";
import { BASE_API } from "../constants";
import { getActiveBusinessId } from "../queries";


// Fetch authentication token
export const fetchSurrealAuthToken = async () => {
  return await fetchWrapper.get(`${BASE_API}/api/auth/token/surreal/?business_id=${getActiveBusinessId()}`);
};

