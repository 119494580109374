import { App, Plugin } from "vue";
import { PressOne } from "@pressone/dialer";

export type PressoneInstance = () => PressOne;
export type SetPressoneInstance = () => void;

let injectDialerInstance: PressoneInstance | null = null;

export const dialerPlugin: Plugin = {
  install(app: App) {
    // set the PressOne instance
    app.config.globalProperties.$setPressoneInstance = (
      data: PressoneInstance
    ): void => {
      injectDialerInstance = data;
    };
    const getNewInstance = (): PressoneInstance => {
      if (!injectDialerInstance) {
        console.error("PressOne instance not set!");
      }
      return injectDialerInstance as PressoneInstance;
    };
    app.config.globalProperties.$getPressoneInstance = getNewInstance;
    app.provide(
      "setPressoneInstance",
      app.config.globalProperties.$setPressoneInstance
    );
    app.provide(
      "getPressoneInstance",
      app.config.globalProperties.$getPressoneInstance
    );
  },
};
