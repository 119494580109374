<template>
  <v-container class="" fluid>
    <v-form ref="form">
      <v-row align="center">
        <v-col cols="12" md="5"
          ><v-card elevation="0">
            <div>
              <header class="mt-5">
                <p class="profile-setting-header">
                  Create a PressOne developer account
                </p>
                <p class="profile-setting-subheader">
                  Enter your information below to get started
                </p>
              </header>
              <v-row class="mt-7 mb-16">
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <label class="mb-2" for="">First Name</label>
                  <v-text-field
                    density="compact"
                    variant="solo"
                    flat
                    :rules="[rules.required]"
                    v-model="userInfo.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-0 pb-1">
                  <label class="mb-2" for="">Last Name</label>
                  <v-text-field
                    density="compact"
                    variant="solo"
                    flat
                    :rules="[rules.required]"
                    v-model="userInfo.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                  <label class="mb-2" for="">Email Address</label>
                  <v-text-field
                    density="compact"
                    variant="solo"
                    flat
                    :rules="[rules.required, rules.email]"
                    v-model="userInfo.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                  <label class="mb-2" for="">Phone Number</label>
                  <v-text-field
                    density="compact"
                    variant="solo"
                    flat
                    readonly
                    disabled
                    v-model="userInfo.mobile"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                  <label class="mb-2" for="">Technology Stack Used</label>
                  <v-combobox
                    density="compact"
                    variant="solo"
                    flat
                    chips
                    multiple
                    :rules="[rules.unempty]"
                    v-model="userInfo.stack"
                    :items="stacks"
                    item-title="title"
                    item-value="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                  <label class="mb-2" for=""
                    >Development Experience Level</label
                  >
                  <v-select
                    density="compact"
                    variant="solo"
                    flat
                    :items="experienceLevels"
                    :rules="[rules.required]"
                    v-model="userInfo.experience_level"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="pt-0 pb-1">
                  <v-btn
                    class="filled-primary-color"
                    flat
                    @click="updateProfile"
                    >Save Changes</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card></v-col
        >
      </v-row>
      <!-- Footer -->
    </v-form>
  </v-container>
</template>
  <script lang="ts" setup>
import { ref, onMounted } from "vue";
import {
  getDeveloperProfile,
  updateDeveloperProfile,
} from "../../../helpers/index";
import { useBusinessStore } from "../../../stores/businesses.store";
import { notify } from "@kyvg/vue3-notification";
import logger from "../../../helpers/logger";

interface UserInfo {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  stack?: string[];
  experience_level?: string;
}
const businessStore = useBusinessStore();
const userInfo = ref<UserInfo>({
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  stack: [],
  experience_level: "",
});

const experienceLevels = [
  { title: "Beginner", value: "beginner" },
  { title: "Junior", value: "junior" },
  { title: "Intermediate", value: "intermediate" },
  { title: "Senior", value: "senior" },
  { title: "Lead", value: "lead" },
  { title: "Expert", value: "expert" },
];
const stacks = [
  "C#",
  "C++",
  "Dart",
  "Elixir",
  "F#",
  "Go",
  "Java",
  "JavaScript",
  "Kotlin",
  "Python",
  "R",
  "Ruby",
  "Rust",
  "Scala",
  "Swift",
  "TypeScript",
];
const rules = {
  required: (v: string) => !!v || "Input Field Required.",
  email: (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid.",
  unempty: (v: string[]) => v.length > 0 || "Input Field Required.",
};

const form = ref<HTMLFormElement | null>(null);
const isLoading = ref(false);

onMounted(async () => {
  try {
    const develeoperProfile = businessStore.activeProfile;
    const { first_name, last_name, email, mobile } = develeoperProfile;
    const response = await getDeveloperProfile();
    const userProfile = response.data;
    userInfo.value = { first_name, last_name, email, mobile };
    userInfo.value.stack = userProfile.stack;
    userInfo.value.experience_level = userProfile.experience_level;
  } catch (error) {
    logger.error(error, "Fetch Developer Details");
  }
});
const updateProfile = async () => {
  try {
    isLoading.value = true;
    const payload = {
      stack: userInfo.value.stack,
      experience_level: userInfo.value.experience_level,
    };
    await updateDeveloperProfile(payload);

    notify({
      type: "success",
      title: "Success",
      text: "Profile updated successfully",
    });

    isLoading.value = false;
    // location.reload();
  } catch (error) {
    isLoading.value = false;
  }
};
</script>
  <style scoped>
.v-text-field ::v-deep .v-field.v-field {
  border-radius: 8px;
  height: 40px;
  border: 1px solid #d0d5dd;
}
.profile-setting-header {
  color: #1b1818;
  font-size: 22px;
  font-weight: 600;
  line-height: 28.8px;
  letter-spacing: -0.02em;
  text-align: left;
}
.redirect-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.3px;
  text-align: left;
  color: #667185;
}
.profile-setting-subheader {
  color: #475367;
  font-size: 15px;
  font-weight: 400;
  line-height: 23.2px;
  text-align: left;
}
label {
  color: #101928;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.3px;
  text-align: left;
}
</style>