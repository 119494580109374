import { ref } from "vue";
import { NumberProvisionPayload } from ".";
import logger from "./logger";
import {
  getAvailableNumbers,
  provisionNumberToUser,
} from "./queries/numberProvision";
import { notify } from "@kyvg/vue3-notification";
export const getIconSrc = (color: string) => {
  return `/assets/Images/icons/bullets/${color}.svg`;
};
export const colors = ["greeen", "purple", "orange"];
export const getBounceClass = (color: string) => {
  return `bounce-${color}`;
};

export const companySizeVariant = [
  // {name: 'Select Business Size', value: ''},
  { name: "5 employees", value: "Below 10" },
  { name: "5 - 20 employees", value: "Above 10" },
  { name: "50 - 100 employees", value: "Above 50" },
  { name: "100 - 500 employees", value: "Above 100" },
  { name: "500 - 1000 employees", value: "Above 500" },
  { name: "1000 employees", value: "Above 1000" },
];

export const purposeVariant = [
  // {name: 'Select Business Size', value: ''},
  { name: "As Primary Business line", value: "As Primary Business line" },
  { name: "For Customer Service", value: "For Customer Service" },
  { name: "For Sales", value: "For Sales" },
  { name: "For Other Purposes.", value: "For Other Purposes." },
];

export const listOfAvailableNumberForProvisioning = async (
  countrycode: string,
  numberType: string,
  isTollFree?: boolean,
  isSpecialNumber?: boolean
) => {
  try {
    const numbers = await getAvailableNumbers(
      countrycode,
      numberType,
      isTollFree,
      isSpecialNumber
    );

    return { numbers: numbers.data };
  } catch (error) {}
};

export const provisionNumber = async (payload: NumberProvisionPayload) => {
  console.log(payload);

  try {
    await provisionNumberToUser(payload);
  } catch (error) {
    notify({
      type: "error",
      text: `${error}`,
    });
    logger.error(error, "Error thrown from provsion number helper");
    throw error;
  }
};
export const emailRule = [
  (v: string) => !!v || "Email is required",
  (v: string) => /.+@.+\..+/.test(v) || "Email must be valid",
];
export const nameRules = [(v: string) => !!v || "Business name is required"];

export const firstNameRule = [
  (v: string) => !!v || "First name is required",
  (v: string) => (!/\s/.test(v) ? !!v : "First name must be a valid name"),
];

export const tagNameRule = [
  (v: string) => !!v || "Tag name is required",
  (v: string) =>
    v.length <= 16 ? !!v : "Tag name must not exceeed 16 characters",
];

export const lastNameRule = [
  (v: string) => !!v || "Last name is required",
  (v: string) => (!/\s/.test(v) ? !!v : "Last name must be a valid name"),
];

export const businessNumberRule = [
  (v: string) => !!v || "Business number is required",
];

export const sizeRule = [(v: string) => !!v || "Business size is required"];
export const purposeRule = [
  (v: string) => !!v || "Business number is required",
];
export const countryRule = [
  (v: string) => !!v || "Country type number is required",
];

export const numberProvisionRedirectUrl = ref("/managers/activities");
// export const createValidationRule = (type: string) => {
//   switch (type) {
//     case "number":
//       return (v: string) => !!v || "Business number is required";
//     case "size":
//       return (v: string) => !!v || "Business size is required";
//     case "purpose":
//       return (v: string) => !!v || "Business purpose is required";
//     default:
//       throw new Error(`Unsupported validation type: ${type}`);
//   }
// };
