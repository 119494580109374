import { defineStore } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    updateUserDetails,
    recommendationData,
    getUserDetailsByTokenNewImplementation,
} from "../helpers";

interface ResponseData {
    [key: string]: any;
}
export const useUsersStore = defineStore({
    id: "users",
    state: () => ({
        users: {},
        user_role: "",
        currentUser: null as ResponseData | null, // currentUser can be of type ResponseData or null
        currentUserBusinessLevel: null as ResponseData | null, // currentUser can be of type ResponseData or null
        registerUser: {
            first_name: "",
            last_name: "",
            mobile: "",
            personal_email: "",
        }, //user signup data
        registerBusiness: {} as recommendationData,
        recommended_plan: {},
        activeTab: 0, //for complete sign-up view
        permissions: {} as any,
        showFeedBackModal: true,
        NIN: "",
        hasFilledEngagement: false,
    }),
    getters: {
        getAgentRole: (state) => {
            return state.permissions || {};
        },
        userProfile: (state) => {
            return state.currentUserBusinessLevel?.profiles;
        },
        businesses: (state) => {
            return state.currentUserBusinessLevel?.business;
        },
    },
    actions: {
        async updateUser(payload: any) {
            await updateUserDetails(payload).then((res: any) => res.data);

            const currentUser =
                await getUserDetailsByTokenNewImplementation().then(
                    (res: any) => res.data
                );

            this.currentUserBusinessLevel = currentUser;
        },

        async fetchUserDetails() {
            const currentUser =
                await getUserDetailsByTokenNewImplementation().then(
                    (res: any) => res.data
                );
            this.currentUserBusinessLevel = currentUser;
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
