import amplitude from "amplitude-js";
import { IS_INTERNAL, IS_PROD } from "../..";
import { AMPLITUDE_EVENTS } from "./events";

const _AMPLITUDE_API_KEY = "d07c19eb097dad4b443472760ae7b59a";

// Events enum

export const signUpEventMap: Record<string, AMPLITUDE_EVENTS> = {
  // maps the value of the query name to corresponding event
  // eg brass to event brass to reduce repetitive if statement
  scalein: AMPLITUDE_EVENTS.SCALEIN_USER_SIGNUP,
  brass: AMPLITUDE_EVENTS.BRASS_USER_SIGNUP,
  sterling: AMPLITUDE_EVENTS.STERLING_USER_SIGNUP,
  printivo: AMPLITUDE_EVENTS.PRINTIVO_USER_SIGNUP,
  side_brief: AMPLITUDE_EVENTS.SIDE_BRIEF_USER_SIGNUP,
};
export const recommendationDataEventMap: Record<string, AMPLITUDE_EVENTS> = {
  // maps the value of the query name to corresponding event
  // eg brass to event brass to reduce repetitive if statement
  "solo plan": AMPLITUDE_EVENTS.SELECTED_SOLO_PLAN,
  "standard plan": AMPLITUDE_EVENTS.SELECTED_STANDARD_PLAN,
  "growth plan": AMPLITUDE_EVENTS.SELECTED_GROWTH_PLAN,
  "international plan": AMPLITUDE_EVENTS.SELECTED_INTERNATIONAL_PLAN,
  "solo plan (quarterly)": AMPLITUDE_EVENTS.SELECTED_SOLO_QUARTERLY_PLAN,
  "standard plan (quarterly)":
    AMPLITUDE_EVENTS.SELECTED_STANDARD_QUARTERLY_PLAN,
  "growth plan (quarterly)": AMPLITUDE_EVENTS.SELECTED_GROWTH_QUARTERLY_PLAN,
  "international (quarterly)":
    AMPLITUDE_EVENTS.SELECTED_INTERNATIONAL_QUARTERLY_PLAN,
  "solo plan (annual)": AMPLITUDE_EVENTS.SELECTED_SOLO_ANNUAL_PLAN,
  "standard plan (annual)": AMPLITUDE_EVENTS.SELECTED_STANDARD_ANNUAL_PLAN,
  "growth plan (annual)": AMPLITUDE_EVENTS.SELECTED_GROWTH_ANNUAL_PLAN,
  "international plan (annual)":
    AMPLITUDE_EVENTS.SELECTED_INTERNATIONAL_ANNUAL_PLAN,
};
export const integrationDataEventMap: Record<string, AMPLITUDE_EVENTS> = {
  // maps the value of the query name to corresponding event
  "zoho crm": AMPLITUDE_EVENTS.CLICK_CONNECT_TO_ZOHO_CRM,
  "hubspot crm": AMPLITUDE_EVENTS.CLICK_CONNECT_TO_HUBSPOT_CRM,
  "google chrome extension":
    AMPLITUDE_EVENTS.CLICK_CONNECT_TO_GOOGLE_CHROME_EXTENSION,
  freshdesk: AMPLITUDE_EVENTS.CLICK_CONNECT_TO_FRESHDESK,
  "zoho phonebridge": AMPLITUDE_EVENTS.CLICK_CONNECT_TO_ZOHO_PHONE_BRIDGE,
  pipedrive: AMPLITUDE_EVENTS.CLICK_CONNECT_TO_PIPE_DRIVE,
  intercom: AMPLITUDE_EVENTS.CLICK_CONNECT_TO_INTERCOM,
  "pressone widget": AMPLITUDE_EVENTS.CLICK_CONNECT_TO_PRESSONE_WIDGET,
  zapier: AMPLITUDE_EVENTS.CLICK_CONNECT_TO_ZAPIER,
};
export const addonEventMap: Record<string, AMPLITUDE_EVENTS> = {
  // maps the value of the query name to corresponding event
  "sales campaign addon": AMPLITUDE_EVENTS.SUBSCRIBED_SALES_CAMPAIGN_ADDON,
};
// Track event
export function trackAmplitudeEvent(
  event: AMPLITUDE_EVENTS | string,
  prop?: Record<string, any>
): void {
  try {
    if (IS_PROD || IS_INTERNAL) {
      amplitude.getInstance().init(_AMPLITUDE_API_KEY);
      amplitude.getInstance().logEvent(event, Object.assign({}, prop));
    } else {
      console.log(
        `[NON-PROD] Amplitude (Track): ${event} - ${JSON.stringify(
          Object.assign({}, prop)
        )}`
      );
    }
  } catch (error) {
    console.log(error, `Could not send event to Amplitude: ${event}`);
  }
}

// Identify user
export function setTrackedUser(identifier: string): void {
  // in this case email is being used as unique identifier of each user
  try {
    if (IS_PROD || IS_INTERNAL) {
      amplitude.getInstance().init(_AMPLITUDE_API_KEY);
      amplitude.getInstance().setUserId(identifier);
    } else {
      console.log(`[NON-PROD] Amplitude (Identify):`);
    }
  } catch (error) {
    console.log(error, `Could not identify user in Amplitude`);
  }
}

// Set user properties
export function setUserProperties(props: Record<string, any>): void {
  try {
    if (IS_PROD || IS_INTERNAL) {
      amplitude.getInstance().init(_AMPLITUDE_API_KEY);
      amplitude.getInstance().setUserProperties(props);
    } else {
      console.log(
        `[NON-PROD] Amplitude (Set user properties): ${JSON.stringify(props)}`
      );
    }
  } catch (error) {
    console.log(error, `Could not set user properties in Amplitude`);
  }
}
