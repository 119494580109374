<template>
  <v-card flat class="modal-card grey-dark py-2 mx-auto" width="500">
    <div
      class="header px-5 py-3 d-flex align-center justify-space-between border-b-sm"
    >
      Start a conversation
      <v-icon
        class="border rounded-sm p-1"
        size="20"
        @click="$emit('closeModal')"
        >mdi-close</v-icon
      >
    </div>
    <form @submit.prevent="handleCreateConversation">
      <div class="px-4 py-4">
        <p class="description">What do you want to discuss?</p>
        <input
          v-model="newConversationTitle"
          variant="outlined"
          placeholder="Add a conversation title"
          class="new-conversation-input"
          hide-details
          density="compact"
          rounded="lg"
          color="grey"
        />
      </div>
      <v-divider></v-divider>
      <v-card-actions class="px-3 pt-3 d-flex justify-end">
        <v-btn
          variant="outlined"
          class="rounded-lg text-grey px-3 cancel"
          type="submit"
          @click="$emit('closeModal')"
        >
          <span class="text-black"> Cancel</span></v-btn
        >
        <v-btn
          color=""
          class="rounded-lg text-white bg-primary px-3"
          type="submit"
          :loading="loading"
          >Start</v-btn
        >
      </v-card-actions>
    </form>
  </v-card>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";

import { useConversationStore, useUsersStore } from "../../../../../stores";
import { createConversation } from "../../../../../helpers/queries/conversations";
import logger from "../../../../../helpers/logger";
import { AMPLITUDE_EVENTS, trackAmplitudeEvent } from "../../../../../helpers";

const router = useRouter();
const conversationStore = useConversationStore();
const userStore = useUsersStore();

const { newConversationTitle } = storeToRefs(conversationStore);

const loading = ref(false);

console.log(userStore.user_role, "USESTORE");

const checkUserRoleAndNavigate = () => {
  if (userStore.user_role === "agent") {
    router.push("/agents/conversations");
  } else if (
    userStore.user_role === "manager" ||
    userStore.user_role === "owner"
  ) {
    router.push("/managers/conversations");
  }
};

const handleCreateConversation = async () => {
  loading.value = true;
  if (newConversationTitle.value) {
    try {
      loading.value = false;
      const response = await createConversation(newConversationTitle.value);
      if (response && response.data && response.data.id) {
        notify({
          type: "success",
          text: "Conversation created successfully",
        });
        trackAmplitudeEvent(AMPLITUDE_EVENTS.CLICKED_CREATE_CONVERSATION);
        conversationStore.newConversationTitle = "";
        conversationStore.getConversationList();
        conversationStore.selectedChatId = response.data.id;
        conversationStore.selectedChat = {
          id: response.data?.id,
          title: response.data?.title,
          description: "",
          participants: response.data?.participants,
          contacts: response.data?.contacts,
          time: response.data?.date_created,
          status: response.data?.status,
          deadline: "",
          type: response.data?.type,
          customer_mobile: "",
          customer_name: "",
          created_by: {
            id: response?.data?.created_by?.user_id,
            name: response?.data?.created_by?.name,
            personal_email: response?.data?.created_by?.email,
            mobile: response?.data?.created_by?.mobile,
          },
        };
        conversationStore.showConversationDetails = true;
        conversationStore.showNewConversationModal = false;
        if (!window.location.href.includes("conversation")) {
          checkUserRoleAndNavigate(); // Navigate based on the user's role
        }
      } else {
        logger.error(response, "Invalid response data");
      }
    } catch (error) {
      notify({
        type: "error",
        text: `${error}`,
      });
    }
  } else {
    notify({
      type: "error",
      text: "You need to enter a title to start a conversation",
    });
  }
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px;
  font-weight: 600;
}

.description {
  font-size: 14px;
  font-weight: 500;
  color: #101928;
}

.cancel {
  color: #d0d5dd;
}

.new-conversation-input {
  outline: none !important;
  height: 48px;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding-left: 12px;
  margin: 8px 0 12px 0;
}
</style>
