import {
    ActiveCallNote,
    CallNoteCreateFromLogs,
    CallNoteUpdateFromLogs,
} from "./../helpers/types/notes";
import { defineStore } from "pinia";
import { addNewCallNote, addNewCallNoteToActiveCall, AMPLITUDE_EVENTS, fetchCallLogDetailsById, trackAmplitudeEvent, updateCallNote } from "../helpers";
import logger from "../helpers/logger";
import { notify } from "@kyvg/vue3-notification";
import { useCallStore } from "./call.store";

interface NoteState {
    activeCallNote: ActiveCallNote | any | null;
    setNoteCounter: boolean
    callnote: string
    activeCallId: number | undefined
    showNoteButton: boolean
}

export const useNotesStore = defineStore({
    id: "note",
    state: (): NoteState => ({
        activeCallNote: null,
        setNoteCounter: false,
        callnote: "",
        activeCallId: undefined,
        showNoteButton: false
    }),
    getters: {},
    actions: {
        async saveCallNote(
            note: string,
            noteObject:
                | CallNoteCreateFromLogs
                | CallNoteUpdateFromLogs
                | ActiveCallNote
                | any,
            idForUpdate: number | undefined = undefined,
        ) {
            const callStore = useCallStore()
            noteObject.note = note;
            noteObject = {
                ...noteObject,
                ...(idForUpdate ? { id: idForUpdate } : {}),
            };
            try {
                if (idForUpdate) {
                    const response = await updateCallNote(
                        {
                            note: noteObject.note,
                        },
                        idForUpdate
                    );
                    this.showNoteButton = false
                    return response.data;
                } else {
                    const createdNote = await addNewCallNote(
                        noteObject,
                        !!noteObject.call_log_id
                    );
                    const response = await fetchCallLogDetailsById(callStore.selectedCallInfo.sip_call_id)
                    callStore.selectedCallInfo = { ...response.data?.data };
                    trackAmplitudeEvent(AMPLITUDE_EVENTS.CALL_NOTE_SAVED)
                    return createdNote.data;
                }
            } catch (e) {
                logger.error(e, "note store");
                throw e;
            }
        },
        // Active call note
        async createNewNote() {
            try {
                const payload = {
                    ...this.activeCallNote,
                    note: this.callnote,
                };
                const response = await addNewCallNoteToActiveCall(payload);
                this.activeCallId = response.data?.id;
                this.setNoteCounter = true;
                notify({
                    text: 'Note saved successfully',
                    type: 'success'
                })
                return this.activeCallId;
            } catch (error) {
                throw error;
            }
        },
        async updateNote() {
            const callStore = useCallStore()
            if (this.activeCallId) {
                try {
                    const response = await updateCallNote({ note: this.callnote }, this.activeCallId);
                    if (callStore.transientCallRecord) {
                        callStore.transientCallRecord.note = response.data
                    }
                    notify({
                        text: 'Note saved successfully',
                        type: 'success'
                    })
                    trackAmplitudeEvent(AMPLITUDE_EVENTS.CALL_NOTE_SAVED)
                } catch (error) {
                    throw error;
                }
            }
        },
        async saveActiveCallNote() {
            console.log(this.callnote);

            if (this.callnote) {
                try {
                    // If save has been clicked, update the note
                    if (this.setNoteCounter) {
                        await this.updateNote();
                    } else {
                        // If save hasn't been clicked, call both
                        await this.createNewNote();
                        await this.updateNote();
                    }
                } catch (error) {
                    logger.error(error, "Update Call Note");
                }
            }
        }
    },
});
