import { ref } from "vue";
import {
  getContacts,
  getContact,
  createContact,
  editContact,
  searchContacts,
  filterContacts,
  deleteContact,
  getContactLogs,
  bulkDelete,
} from "./queries/contact";
import { TabFilterItemTypes, createContactType } from "./types/contact";
// import { useNumberStore } from "../stores";
import { getActiveBusinessId } from "./queries";
import { notify } from "@kyvg/vue3-notification";
import * as XLSX from "xlsx";
import emitter from "./integrations/emitter";


export const listContacts = async (link?: string) => {
  const contacts = ref([]) as any;
  if (link) {
    await getContacts(getActiveBusinessId(), link).then(
      (res) => (contacts.value = res.data || [])
    );
  } else
    await getContacts(getActiveBusinessId()).then(
      (res) => (contacts.value = res.data || [])
    );
  return { contacts: contacts.value };
};

export const getSingleContact = async (contact_id: any) => {
  const contactDetails = ref({}) as any;
  await getContact(getActiveBusinessId(), contact_id).then(
    (res) => (contactDetails.value = res.data || {})
  );
  return { contact: contactDetails.value };
};

export const handleCreateContact = async (payload: createContactType) => {
  try {
    await createContact(payload, getActiveBusinessId());

    notify({
      text: "Contact created successfully",
      type: "success",
    });
  } catch (error: any) {
    notify({
      text: error,
      type: "error",
    });
  }
};

export const handleEditContacts = async (
  contactId: number,
  payload: object
) => {
  try {
    await editContact(contactId, payload, getActiveBusinessId());

    notify({
      text: "Contact updated successfully",
      type: "success",
    });
    window.location.reload();
  } catch (err) {
    notify({
      text: "Error while updating contact",
      type: "error",
    });
  }
};

export const handleSearchContacts = async (text: string) => {
  const contactsList = ref([]);
  const nextLink = ref<null | string>(null);
  await searchContacts(text, getActiveBusinessId()).then((res) => {
    contactsList.value = res.data.data || [];
    nextLink.value = res.data.next || null;
  });

  return { contacts: contactsList.value, next: nextLink.value };
};
export const filterContactList = async (
  filterType: TabFilterItemTypes,
  tagsFilter?: boolean
) => {
  const contactsList = ref([]);
  const nextLink = ref<null | string>(null);
  if (tagsFilter) {

    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "-date_created",
      tagsFilter
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  } else if (filterType === "all") {
    await getContacts(getActiveBusinessId()).then((res) => {
      contactsList.value = res.data.data || [];
    });
  } else if (
    filterType === "recently_added" ||
    filterType === "recently_called"
  ) {
    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "-date_created"
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  } else if (filterType === "my_contacts") {
    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "contact_name"
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  }
  return { contacts: contactsList.value, next: nextLink.value };
};

export const handleDeleteContact = async (id: any) => {
  try {
    await deleteContact(id, getActiveBusinessId());

    notify({
      text: "Contact deleted successfully",
      type: "success",
    });
    setTimeout(() => {
      window.location.reload;
    }, 1000);
  } catch (error) {
    notify({
      text: "Error while deleting contact",
      type: "error",
    });
  }
};

export const handleBulkDelete = async (
  contact_ids: Array<number>
): Promise<void> => {
  try {
    await bulkDelete(getActiveBusinessId(), contact_ids);
    notify({
      text: "Contacts deleted successfully",
      type: "success",
    });
    setTimeout(() => {
      // window.location.reload();
      emitter.emit("fetchContacts");
    }, 1000);
  } catch (error) {
    notify({
      text: "Error while deleting contacts",
      type: "error",
    });
  }
};

export const contactLogs = async (phone_number: any) => {
  const callLogs = ref([]) as any;
  await getContactLogs(getActiveBusinessId(), phone_number).then(
    (res) => (callLogs.value = res.data || [])
  );

  return { contacts: callLogs.value };
};

const isValidPhoneNumber = (input: string): boolean => {
  return /^\d+$/.test(input);
};

const formatPhoneNumber = (phoneNumber: any): string => {
  // Convert phoneNumber to a string incase it is not
  phoneNumber = phoneNumber.toString();
  // Remove the leading + if present to standardize the format
  const standardizedPhoneNumber = phoneNumber.startsWith("+")
    ? phoneNumber.substring(1)
    : phoneNumber;

  // Check if standardizedPhoneNumber starts with 234
  if (standardizedPhoneNumber.startsWith("234")) {
    // Check if there's a 0 immediately after 234, if so, drop the 234, otherwise replace 234 with 0
    return standardizedPhoneNumber[3] === "0"
      ? standardizedPhoneNumber.substring(3)
      : "0" + standardizedPhoneNumber.substring(3);
  }
  if (!standardizedPhoneNumber.startsWith("0")) {
    return "0" + standardizedPhoneNumber;
  }

  // Return the original phoneNumber if it doesn't start with +234 or 234
  return phoneNumber;
};

const isValidEmail = (input: string): boolean => {
  if (input == "") {
    return true;
  }
  return /.+@.+\..+/.test(input);
};

const allDataArray = ref<any[]>([]);

const isPrivate = ref(true);

const singleMatchAction = ref("NO_ACTION");

const multipleMatchResolutionStrategy = ref("USE_RECENTLY_CREATED");

const headers = ref<string[]>()

export const useContact = () => {
  // central state to keep array of all imported contacts

  const fileUpload = async (file: File) => {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);

    const sheetDataArray = [] as any[][];

    let lowerCaseHeaders;

    await new Promise((resolve, reject) => {
      fileReader.onload = resolve;
      fileReader.onerror = reject;
    });

    const arrayBuffer = fileReader.result as ArrayBuffer;

    const workbook = XLSX.read(arrayBuffer, { type: "array" });

    const errorIndexes = [] as number[];

    workbook.SheetNames.forEach((sheetName) => {
      // const XL_row_obj = XLSX.utils.sheet_to_row_obje
      const sheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        defval: "",
      });

      const headers = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
      })[0] as string[];

      //NB: Convert all headers to lowercase for case-insensitive headers
      lowerCaseHeaders = headers.map((header) => header.toLowerCase());

      //NB: Define the headers that are required. At least this two must be there
      // const requiredHeaders = ["name", "phone number"];

      //NB: Define the headers that are allowed (including the required ones). This is to check and prevent upload for users that may upload wrong headers and comeback to say they cant find their information
      const allowedHeaders = ["name", "phone number", "email", "company"];

      lowerCaseHeaders.forEach((element, index: number) => {
        const containsElement = allowedHeaders.includes(element.toLowerCase());
        if (!containsElement) {
          errorIndexes!.push(index);
        }
      });

      // NB:  Disable upload for empty file
      if (data.length === 0) {
        throw new Error(
          "The uploaded file is empty. Please ensure the file contains data and try again." // check for empty upload
        );
      }

      //NB: Find the actual header name eg for "NAME" header any case is allowed so far it is the correct combination.
      const nameHeader = headers[lowerCaseHeaders.indexOf("name")];
      const phoneNumberHeader =
        headers[lowerCaseHeaders.indexOf("phone number")];

      // const emailHeader = headers[lowerCaseHeaders.indexOf("email")];
      //NB: Find the actual header name eg for "NAME" header any case is allowed so far it is the correct combination.
      data.forEach((row: any) => {
        sheetDataArray.push(Object.values(row));

        if (nameHeader && !row[nameHeader]) {
          throw new Error(
            `The name at row ${row["__rowNum__"] + 1
            }  is empty. Please ensure you fill the field and try again.`
          );
        }

        if (phoneNumberHeader && !row[phoneNumberHeader]) {
          throw new Error(
            `The phone number at row ${row["__rowNum__"] + 1
            }  is empty. Please ensure you fill the field and try again.`
          );
        }

        if (phoneNumberHeader && !isValidPhoneNumber(row[phoneNumberHeader])) {
          throw new Error(
            `The phone number at row ${row["__rowNum__"] + 1} "${row["PHONE NUMBER"]
            }" is not valid. Phone number must contain only digit`
          );
        }

        if (
          phoneNumberHeader &&
          row[phoneNumberHeader]?.toString()?.length < 7
        ) {
          throw new Error(
            `The phone number at row ${row["__rowNum__"] + 1} *${row[phoneNumberHeader]
            }* is not valid. Please ensure it contains a valid phone number and try again`
          );
        }

      });
    });

    return { errorIndexes, lowerCaseHeaders, sheetDataArray };
  };

  const validateContacts = (
    headers: string[],
    sheetDataArray: string[][],
    isPrivate: boolean
  ) => {
    allDataArray.value = [];
    const nameHeader = safeIndexOf(headers, "name");
    const phoneNumberHeader = safeIndexOf(headers, "phone number");
    const companyHeader = safeIndexOf(headers, "company");
    const emailHeader = safeIndexOf(headers, "email");

    const requiredHeaders = ["name", "phone number"];

    const lowerCaseHeaders = headers.map((header) => header.toLowerCase());

    //  NB: Check if all required headers are present. So we allow people upload at least those two.
    if (!requiredHeaders.every((header) => lowerCaseHeaders.includes(header))) {
      // NB: throw error if the required header arent present
      throw new Error(
        "Invalid headers. Headers must include 'NAME' and 'PHONE NUMBER'."
      );
    }

    sheetDataArray.forEach((row: string[], index) => {

      if (nameHeader && !row[nameHeader]) {
        throw new Error(
          `The name at row ${index + 1
          }  is empty. Please ensure you fill the field and try again.`
        );
      }

      if (phoneNumberHeader && !row[phoneNumberHeader]) {
        throw new Error(
          `The phone number at row ${index + 1
          }  is empty. Please ensure you fill the field and try again.`
        );
      }

      if (emailHeader && !isValidEmail(row[emailHeader])) {
        throw new Error(
          `The email at row ${index + 1} "${row[emailHeader]
          }" is not valid. Email must be a valid email address`
        );
      }

      if (phoneNumberHeader && !isValidPhoneNumber(row[phoneNumberHeader])) {
        throw new Error(
          `The phone number at row ${index + 1} "${row[phoneNumberHeader]
          }" is not valid. Phone number must contain only digit`
        );
      }

      if (phoneNumberHeader && row[phoneNumberHeader]?.toString()?.length < 7) {
        throw new Error(
          `The phone number at row ${index + 1} *${row[phoneNumberHeader]
          }* is not valid. Please ensure it contains a valid phone number and try again`
        );
      }

      const obj: any = {
        email: emailHeader && row[emailHeader] ? row[emailHeader] : null,
        // this is because an index of zero resolves to false
        name: row[nameHeader!] || null,
        number:
          phoneNumberHeader && row[phoneNumberHeader]
            ? formatPhoneNumber(row[phoneNumberHeader])
            : null,
        company:
          companyHeader && row[companyHeader] ? row[companyHeader] : null,
        is_private: isPrivate,
      };

      allDataArray.value.push(obj);
    });
  };

  return {
    validateContacts,
    allDataArray,
    isPrivate,
    fileUpload,
    singleMatchAction,
    multipleMatchResolutionStrategy,
    headers
  };
};

function safeIndexOf<T>(arr: T[], item: T): number | null {
  const index = arr.indexOf(item);
  return index !== -1 ? index : null;
}
